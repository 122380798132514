import React, { useEffect, useState } from "react"
import useStores from "../hooks/use-stores"
import { observer } from "mobx-react";
import { Link, navigate } from "gatsby";
import { Box, Button, CircularProgress, Divider, TextField, Typography } from "@mui/material";
import VmButton from "../components/shared-button";
import { GetUserStatus, isBrowser, Language, NavListByCategory, ProdEnvCheck, UserStatus } from "../constants/options";
import { t } from "i18next";
import Layout from "../components/shared-layout";
import { ITEM_PERFECT_INLINED } from "../constants/style";
import { DateUtils } from "../utilities/date";

const VisitReplacementPage = observer(() => {
  const { userStore, rootStore } = useStores();
  const [startDateTime, setStartDateTime] = useState<string>("");
  const [endDateTime, setEndDateTime] = useState<string>("");
  const [enableEdit, setEnableEdit] = useState<boolean>(false);

  return (
    <Layout pageName="Visit Replacement Request">
      <Typography variant="h4" textAlign={"center"} marginY={4}>Visit Replacement</Typography>
      <div className="bg-white p-4 mx-8 rounded-lg">
        <Typography variant="h6" fontWeight={"bold"} textAlign="center">Visit Title: <span className="font-normal">Test Visit</span></Typography>
        <Typography variant="h6" fontWeight={"bold"} textAlign="center">Visit Description: <span className="font-normal">This is the visit description</span></Typography>

        <div className="grid grid-cols-2 gap-4 my-4">
          <div className="bg-highlight text-center rounded-lg text-white p-2">
            <Typography marginBottom={1}>Original Date/Time</Typography>
            <Typography variant="h5">2022/08/20 10:00 - 12:00</Typography>
          </div>
          <div className="bg-highlight text-center rounded-lg text-white p-2">
            <Typography marginBottom={1}>New Date/Time</Typography>
            <Typography variant="h5">2022/08/21 10:00 - 12:00</Typography>
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              className={`${enableEdit ? "bg-gray-400" : "bg-green-400"} text-white rounded-lg px-8 py-2 shadow-lg hover:shadow-sm`}
              style={{ width: 'fit-content' }}
              disabled={enableEdit}
            // onClick={onClickBack}
            >
              <Typography variant="h6">Accept Replacement</Typography>
            </button>
          </div>
          <button
            type="button"
            className="bg-red-500 text-center text-white rounded-lg px-8 py-2 shadow-lg hover:shadow-sm"
            style={{ width: 'fit-content' }}
            onClick={() => setEnableEdit(true)}
          >
            <Typography variant="h6">Edit Replacement</Typography>
          </button>
        </div>


        {enableEdit &&
          <>
            <Divider sx={{ marginY: 2 }} />
            <Typography variant="h6">Edit Visit Time</Typography>
            <div className="flex p-4">
              <TextField
                value={startDateTime}
                label={t('START_TIME')}
                type={"datetime-local"}
                variant="outlined"
                sx={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
                onChange={(value) => setStartDateTime(value.target.value)}
              />
              <TextField
                value={endDateTime}
                label={t('END_TIME')}
                type={"datetime-local"}
                variant="outlined"
                sx={{ width: '100%', marginLeft: 2 }}
                InputLabelProps={{ shrink: true }}
                onChange={(value) => setEndDateTime(value.target.value)}
              />
            </div>
            {DateUtils.getHourDifferences(startDateTime, endDateTime) > 8 &&
              <Typography textAlign="center" color="red">{t('OVER_8_HOURS_WARNING')}</Typography>}

            {startDateTime !== "" && endDateTime !== "" &&
              <>
                <Typography marginY={1} variant="h6" textAlign={"center"}>
                  New Time: <u>{DateUtils.getDayDefinition(new Date(startDateTime))}, {startDateTime.split('T')[0] + " " + startDateTime.split('T')[1]} to {endDateTime.split('T')[1]}</u>
                </Typography>
                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="bg-green-400 text-center text-white rounded-lg px-8 py-2 shadow-lg hover:shadow-sm"
                      style={{ width: 'fit-content' }}
                      onClick={() => setEnableEdit(!enableEdit)}
                    >
                      <Typography variant="h6">Send New Replacement</Typography>
                    </button>
                  </div>
                  <button
                    type="button"
                    className="bg-red-500 text-center text-white rounded-lg px-8 py-2 shadow-lg hover:shadow-sm"
                    style={{ width: 'fit-content' }}
                    onClick={() => setEnableEdit(false)}
                  >
                    <Typography variant="h6">Cancel</Typography>
                  </button>
                </div>
              </>
            }
          </>}
      </div>
    </Layout>
  )
})

export default VisitReplacementPage;
