export class DateUtils {
    
    static get tomorrow(){
        return DateUtils.addDays(new Date(), 1)
    }

    static get dayAfterTomorrow(){
        return DateUtils.addDays(new Date(), 2)
    }

    static get oneYearAfter(){
        return DateUtils.addDays(new Date(), 365)
    }

    static getDatesBetween(startDate, stopDate) {
        var dateArray: Date[] = [];
        var currentDate = startDate;
        dateArray.push(currentDate)
        while (currentDate < stopDate) {
            currentDate = DateUtils.addDays(currentDate, 1);
            dateArray.push(currentDate);
        }
        console.log(`get dates between ${startDate.toString()} ${stopDate.toString()}`)
        return dateArray;
    }

    static addDays(date, days){
        const newDate = new Date(date)
        newDate.setDate(date.getDate() + days);
        return newDate;
    }

    static getHourDifferences = (startTime: string, endTime: string) => {
        let diff = (new Date(startTime).getTime() - new Date(endTime).getTime()) / 1000;
        diff /= (60 * 60);
        return Math.abs(Math.round(diff));
    }

    static getDayDefinition(date: any) {
        switch (date.getDay()) {
            case 0: return "Sunday";
            case 1: return "Monday";
            case 2: return "Tuesday";
            case 3: return "Wednsday";
            case 4: return "Thursday";
            case 5: return "Friday";
            case 6: return "Saturday"
        }
    }

    static onConvertDateFormat(date: string) {
        let year = date.split('-')[0];
        let month = date.split('-')[1];
        let day = date.split('-')[2];
        return `${day}/${month}/${year}`
    }


}